import Color from 'color'

export const colors = {
  black: '#000000',
  gray20: '#333333',
  gray46: '#767676',
  gray77: '#C4C4C4',
  gray89: '#E4E4E4',
  gray95: '#F2F2F2',
  gray97: '#F8F8F8',
  white: '#FFFFFF',

  flamingoOrange: '#F15824',
  pigmentGreen: '#1DB754',
  salemGreen: '#18883E',
  sanMarinoBlue: '#3D70B2',
  terracottaRed: '#A94442',
  saleRed: '#DA3A2C',
  littleLeagueBlue: '#4487D2',

  grayDark: '#333',
  grayMedium: '#767676',
  grayLight: '#979797',
  grayLighter: '#E8E8E8',
  grayLightest: '#F8F8F8',
  green1: '#1DB754',
  actionOrange: '#f15824',
  blue: '#3D70B2',

  activeBG: '#FD8416',
  activeHover: '#FE9433',
  helpText: '#1979A8',
  mutedText: '#818181',
  lightBG: '#E9E9E9',
  lightBorder: '#E8E8E8',
  defaultText: '#333333',
  headingText: '#C4C4C4',
  placeholderText: '#999999',
  errorRed: '#A94442',
  accentBG: '#F9F9F9',
  defaultBorder: '#CCC',
  focusedBorder: '#66afe9',
  successText: '#468847',
  successBG: '#DFF0D8',
  linkText: '#3D70B2',

  inputBG: '#F8F8F8',
  inputFocus: '#FFFFFF',
  inputActive: '#FFFFFF',
  inputBorder: '#E2E5E6',
  inputBorderFocus: '#A1A4A6',
  inputBorderError: '#CA491E',
  inputBorderSuccess: '#3D70B2',
  inputIcon: '#CED0DA',
  inputPlaceholder: '#686F77',
  inputPlaceholderFocus: '#B9B9B9',
  inputText: '#333333',

  primaryBG: '#F15824',
  strokeHover: '#FAFAFA',
  strokeActive: '#F5F5F5',
  strokeText: '#333333',
  blueShadow: 'rgba(24, 144, 255, 0.2)'
}

function generatePaletteFromBase(base, text = colors.white) {
  return {
    base: Color(base).hex(),
    background: Color(base)
      .mix(Color(colors.white), 0.1)
      .hex(),
    border: Color(base)
      .mix(Color(colors.black), 0.1)
      .hex(),
    shadow: `0 2px ${Color(colors.black).fade(0.8)}`,
    text,
    state: {
      active: Color(base)
        .mix(Color(colors.black), 0.1)
        .hex(),
      focus: `0 0 0 4px ${Color(base).fade(0.8)}`,
      hover: Color(base)
        .mix(Color(colors.black), 0.05)
        .hex()
    }
  }
}

export const palettes = {
  default: {
    background: colors.gray97,
    border: colors.gray89,
    text: colors.gray20
  },
  info: generatePaletteFromBase(colors.sanMarinoBlue),
  primary: generatePaletteFromBase(colors.flamingoOrange),
  secondary: generatePaletteFromBase(colors.sanMarinoBlue),
  success: generatePaletteFromBase(colors.pigmentGreen),
  warning: generatePaletteFromBase(colors.flamingoOrange),
  error: generatePaletteFromBase(colors.terracottaRed),
  outline: generatePaletteFromBase(colors.white, colors.gray20),
  disabled: {
    base: colors.gray95,
    shadow: 'none',
    text: colors.gray77
  }
}
