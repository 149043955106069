import PropTypes from 'prop-types'
import React from 'react'

// Define all icons we plan to use here
import {
  faAngleLeft,
  faAngleRight,
  faArrowLeft,
  faBars,
  faCalendar,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCloudUploadAlt,
  faExchangeAlt,
  faFile,
  faFolder,
  faHome,
  faImage,
  faLock,
  faMinus,
  faPlus,
  faQuestionCircle,
  faSearch,
  faSearchPlus,
  faShoppingCart,
  faSpinner,
  faStarHalf,
  faTimes,
  faTruck
} from '@fortawesome/free-solid-svg-icons'

import { faCopy, faEnvelope } from '@fortawesome/free-regular-svg-icons'

import {
  faCcAmex,
  faCcDiscover,
  faCcMastercard,
  faCcVisa
} from '@fortawesome/free-brands-svg-icons'

export const icons = {
  angleLeft: faAngleLeft,
  angleRight: faAngleRight,
  arrowLeft: faArrowLeft,
  bars: faBars,
  calendar: faCalendar,
  ccAmex: faCcAmex,
  ccDiscover: faCcDiscover,
  ccMastercard: faCcMastercard,
  ccVisa: faCcVisa,
  check: faCheck,
  checkCircle: faCheckCircle,
  chevronDown: faChevronDown,
  chevronLeft: faChevronLeft,
  chevronRight: faChevronRight,
  chevronUp: faChevronUp,
  circle: faCircle,
  cloudUpload: faCloudUploadAlt,
  copy: faCopy,
  envelope: faEnvelope,
  exchangeAlt: faExchangeAlt,
  file: faFile,
  folder: faFolder,
  home: faHome,
  image: faImage,
  lock: faLock,
  minus: faMinus,
  plus: faPlus,
  questionCircle: faQuestionCircle,
  search: faSearch,
  searchPlus: faSearchPlus,
  shoppingCart: faShoppingCart,
  spinner: faSpinner,
  starHalf: faStarHalf,
  times: faTimes,
  truck: faTruck
}

const FontAwesome = (props) => {
  const {
    icon: { icon, prefix, iconName },
    className
  } = props
  const [width, height] = icon
  const faW = Math.ceil((width / height) * 16)

  // We approximate the fontawesome.icon rendering function so that we don't
  // have to import `@fortawesome/fontawesome` as it's quite large.
  return (
    <svg
      aria-hidden="true"
      data-prefix={prefix}
      data-icon={iconName}
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-inline--fa fa-${iconName} fa-w-${faW} ${className}`}
      viewBox={`0 0 ${width} ${height}`}
    >
      <path fill="currentColor" d={icon[4]} />
    </svg>
  )
}

FontAwesome.propTypes = {
  icon: PropTypes.object,
  className: PropTypes.string
}

/**
 * Helper pure component that makes it easy to render FontAwesome icons.
 * @author wes@teamrazr.com (Wes Carr)
 */
const Icon = ({ className, icon, size = 'sm', spin, ...rest }) => (
  <span className="fa" {...rest}>
    <FontAwesome
      icon={icon}
      className={`fa-${size} ${spin ? 'fa-spin' : ''} ${className || ''}`}
    />
  </span>
)

Icon.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.object,
  size: PropTypes.oneOf([
    'xs',
    'sm',
    'lg',
    '2x',
    '3x',
    '4x',
    '5x',
    '7x',
    '10x'
  ]),
  spin: PropTypes.bool
}

export default Icon
