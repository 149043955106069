import { actions } from '@teamrazr/redux'
const { types } = actions

const INITIAL_STATE = {
  breakpoint: 'sm'
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.MEDIA_RESIZE:
      return {
        ...state,
        breakpoint: action.breakpoint
      }

    default:
      return state
  }
}
