import { helpers } from '@teamrazr/redux'

const globals = helpers.createApiReducer('globals')

const initialState = {
  meta: {
    title:
      'High School Apparel, College Fan Gear, Sports Jerseys | Prep Sportswear',
    description:
      'Prep Sportswear is the ultimate High School Sports Apparel and College Fan Gear Store. T-Shirts, Sweatshirts, Hoodies, and Jerseys for every High School and College.'
  },
  footer: null
}

export default function (state = initialState, action) {
  return globals(state, action)
}
