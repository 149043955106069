import { datadogRum } from '@datadog/browser-rum'
import * as Sentry from '@sentry/react'
import { css } from 'glamor'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Helmet from 'react-helmet'

import { connect } from 'react-redux'
import { AttentiveTag } from '@/bundle/client/components/tags/attentive-tag'
import { StripeTag } from '@/bundle/client/components/tags/stripe-tag'
import { DATA_DOG_TRACE_VERSION } from '@/constants/datadog'
import { setMediaBreakpoint } from '@/redux/actions/media'
import { breakpoints } from '@/styles'
import ErrorBoundary from './error_boundary'
import FooterNav from './footernav'
import HeaderNav from './headernav'
import ServerContext from './serverContext'
import { TrackingProvider, ViewContext } from './tracking'
import { checkIsProdWithoutFake, isTrue } from '@/shared/utils'
import { pushGTMData } from '@/redux/actions/pushGTMData'

let xsMediaQueryList
let smMediaQueryList
let mdMediaQueryList
let lgMediaQueryList
let xlMediaQueryList

const style = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh'
  }),
  content: css({
    flex: '1 auto'
  })
}

Sentry.init({
  dsn: 'https://d770125a0ba22c3cc360853079bc0f0f@o4506742884728832.ingest.sentry.io/4506742886891520',
  integrations: []
})

class Main extends Component {
  constructor(props) {
    super(props)
  }

  static initialize(props) {
    return HeaderNav.initialize(props)
  }

  componentDidMount() {
    const gtmData = {
      using_stripe_checkout: isTrue(this.props?.stripe?.USE_STRIPE_CHECKOUT)
    }
    if (!checkIsProdWithoutFake(this.props?.env)) {
      gtmData.debug_mode = true
    }
    pushGTMData(gtmData).then(() => {})

    xsMediaQueryList = window.matchMedia(breakpoints.xs)
    smMediaQueryList = window.matchMedia(breakpoints.sm)
    mdMediaQueryList = window.matchMedia(breakpoints.md)
    lgMediaQueryList = window.matchMedia(breakpoints.lg)
    xlMediaQueryList = window.matchMedia(breakpoints.xl)

    xsMediaQueryList.addListener(this.setWindowXs)
    smMediaQueryList.addListener(this.setWindowSm)
    mdMediaQueryList.addListener(this.setWindowMd)
    lgMediaQueryList.addListener(this.setWindowLg)
    xlMediaQueryList.addListener(this.setWindowXl)

    if (xsMediaQueryList.matches) {
      this.props.dispatch(setMediaBreakpoint('xs'))
    } else if (smMediaQueryList.matches) {
      this.props.dispatch(setMediaBreakpoint('sm'))
    } else if (mdMediaQueryList.matches) {
      this.props.dispatch(setMediaBreakpoint('md'))
    } else if (lgMediaQueryList.matches) {
      this.props.dispatch(setMediaBreakpoint('lg'))
    } else if (xlMediaQueryList.matches) {
      this.props.dispatch(setMediaBreakpoint('xl'))
    }

    // Initialize DataDog Real User Monitoring (RUM)
    datadogRum.init({
      applicationId: this.props.dataDogConfig.applicationId,
      clientToken: this.props.dataDogConfig.clientToken,
      site: 'datadoghq.com',
      service: this.props.dataDogConfig.service,
      env: this.props.dataDogConfig.environment,
      // Specify a version number to identify the deployed version of your application in Datadog
      version: DATA_DOG_TRACE_VERSION,
      // This defaults to 100% if omitted
      premiumSampleRate: 0,
      sampleRate: this.props.dataDogConfig.rate,
      trackInteractions: this.props.dataDogConfig.trackInteractions
    })
  }

  componentWillUnmount() {
    xsMediaQueryList.removeListener(this.setWindowXs)
    smMediaQueryList.removeListener(this.setWindowSm)
    mdMediaQueryList.removeListener(this.setWindowMd)
    lgMediaQueryList.removeListener(this.setWindowLg)
  }

  setWindow = (event, size) => {
    if (event.matches) {
      this.props.dispatch(setMediaBreakpoint(size))
    }
  }

  setWindowXs = (event) => this.setWindow(event, 'xs')
  setWindowSm = (event) => this.setWindow(event, 'sm')
  setWindowMd = (event) => this.setWindow(event, 'md')
  setWindowLg = (event) => this.setWindow(event, 'lg')
  setWindowXl = (event) => this.setWindow(event, 'xl')

  render() {
    const {
      children,
      env,
      globals,
      hosts,
      location,
      tracking,
      googleSiteVerificationToken,
      yotpoKey
    } = this.props
    const {
      meta: { title, description }
    } = globals
    const admin = /^\/admin\//.test(location.pathname)

    // Extract component name
    const { displayName } = children.type.WrappedComponent || children.type

    return (
      <ServerContext.Provider value={{ env, hosts }}>
        <TrackingProvider {...tracking}>
          <div {...style.container}>
            <Helmet>
              <title>{title}</title>
              <meta name="description" content={description} />
              {googleSiteVerificationToken && (
                <meta
                  name="google-site-verification"
                  content={googleSiteVerificationToken}
                />
              )}
              <meta
                property="og:title"
                content="High School Apparel, College Fan Gear, Sports Jerseys | Prep Sportswear"
              />
            </Helmet>

            <noscript suppressHydrationWarning>
              <div className="container">
                <div className="noscript">
                  <p>JavaScript is currently turned off.</p>
                  <p>
                    Turn it on in <em>Settings</em> to view this website.
                  </p>
                </div>
              </div>
              <iframe
                src="https://www.googletagmanager.com/ns.html?id=GTM-PLLQ8G6"
                height="0"
                width="0"
                style={{ display: 'none', visibility: 'hidden' }}
              ></iframe>
            </noscript>

            {!admin && <HeaderNav location={location} />}
            <ErrorBoundary {...style.content}>
              <ViewContext.Provider value={displayName}>
                {children}
              </ViewContext.Provider>
            </ErrorBoundary>
            {!admin && <FooterNav location={location} />}
            <AttentiveTag />
            <StripeTag />
          </div>
        </TrackingProvider>
      </ServerContext.Provider>
    )
  }
}

Main.propTypes = {
  children: PropTypes.element,
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  env: PropTypes.string,
  globals: PropTypes.object.isRequired,
  hosts: PropTypes.object.isRequired,
  tracking: PropTypes.object.isRequired,
  googleSiteVerificationToken: PropTypes.string,
  dataDogConfig: PropTypes.object.isRequired,
  stripe: PropTypes.object,
  yotpoKey: PropTypes.string
}

export default connect(
  ({ env, globals, hosts, tracking, tokens, dataDog, stripe, yotpo }) => ({
    env,
    globals,
    hosts,
    tracking,
    googleSiteVerificationToken: tokens
      ? tokens.GOOGLE_SITE_VERIFICATION_TOKEN
      : undefined,
    dataDogConfig: dataDog,
    stripe,
    yotpoKey: yotpo?.appKey
  })
)(Main)
