import { helpers } from '@teamrazr/redux'

const defaultProductReviewsReducer = helpers.createApiReducer('product_reviews')

export default function productReviewsReducer(
  state,
  action: App.Actions.APIResponseSuccess<
    App.Yotpo.WidgetV1API.Root,
    'product_reviews',
    App.Yotpo.WidgetV1API.Root
  >
) {
  if (/PRODUCT_REVIEWS/i.test(action.type)) {
    // @ts-expect-error We want to change Yotpo's status and review response to an array of just reviews
    action = {
      ...action,
      body: [action?.body?.response]
    } as App.Actions.APIResponseSuccess<
      App.Yotpo.WidgetV1API.Response,
      'product_reviews',
      App.Yotpo.WidgetV1API.Response[]
    >
  }
  return defaultProductReviewsReducer(state, action)
}
