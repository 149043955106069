import PropTypes from 'prop-types'
import React from 'react'
import IndexRoute from 'react-router/lib/IndexRoute'
import Route from 'react-router/lib/Route'
import Main from '@/components/main'
import { urlRoutes } from '@/shared/urlRoutes'
import { loadAsync, onChange, reloadPage } from '../routeUtils'

const createRoutes = (routes, props) =>
  routes.map((path) => <Route path={path} key={path} {...props} />)

// TODO(wes): Can 'Main' be loaded with getComponent depending on the route?
const Routes = ({ dispatch, getState }) => (
  <Route path="/" component={Main} onChange={onChange(dispatch, getState)}>
    <IndexRoute
      getComponent={loadAsync(
        () => import(/* webpackChunkName: "home"*/ './components/home')
      )}
    />

    {createRoutes(urlRoutes.genericProduct, {
      getComponent: loadAsync(
        () =>
          import(
            /* webpackChunkName: "generic_product" */ './components/product/generic'
          )
      )
    })}

    {createRoutes(urlRoutes.product, {
      getComponent: loadAsync(
        () =>
          import(
            /* webpackChunkName: "product" */ './components/product/details'
          )
      )
    })}
    {createRoutes(urlRoutes.productList, {
      getComponent: loadAsync(
        () =>
          import(
            /* webpackChunkName: "product_list" */ './components/product/list'
          )
      )
    })}
    {createRoutes(urlRoutes.storeMain, {
      getComponent: loadAsync(
        () =>
          import(/* webpackChunkName: "store_main" */ './components/store/main')
      )
    })}
    {createRoutes(urlRoutes.channelMain, {
      getComponent: loadAsync(
        () =>
          import(
            /* webpackChunkName: "channel_main" */ './components/channel/main'
          )
      )
    })}
    {createRoutes(urlRoutes.channelList, {
      getComponent: loadAsync(
        () =>
          import(
            /* webpackChunkName: "channel_list" */ './components/channel/list'
          )
      )
    })}
    {createRoutes(urlRoutes.shippingBilling, {
      getComponent: loadAsync(
        () =>
          import(
            /* webpackChunkName: "shipping_billing" */ './components/checkout/shippingBilling'
          )
      )
    })}
    {createRoutes(urlRoutes.cart, {
      getComponent: loadAsync(
        () =>
          import(/* webpackChunkName: "cart" */ './components/checkout/cart')
      )
    })}
    {createRoutes(urlRoutes.reviews, {
      getComponent: loadAsync(
        () => import(/* webpackChunkName: "reviews" */ './components/reviews')
      )
    })}
    {createRoutes(urlRoutes.confirm, {
      getComponent: loadAsync(
        () =>
          import(
            /* webpackChunkName: "confirm" */ './components/checkout/confirm'
          )
      )
    })}
    {createRoutes(urlRoutes.addStore, {
      getComponent: loadAsync(
        () =>
          import(/* webpackChunkName: "add_store" */ './components/store/add')
      )
    })}
    {createRoutes(urlRoutes.claimStore, {
      getComponent: loadAsync(
        () =>
          import(
            /* webpackChunkName: "claim_store" */ './components/store/claim'
          )
      )
    })}
    {createRoutes(urlRoutes.storeDashboard, {
      getComponent: loadAsync(
        () =>
          import(
            /* webpackChunkName: "store_dashboard" */ './components/store/dashboard'
          )
      )
    })}
    {createRoutes(urlRoutes.editStore, {
      getComponent: loadAsync(
        () =>
          import(/* webpackChunkName: "edit_store" */ './components/store/edit')
      )
    })}
    {createRoutes(urlRoutes.requestAddStore, {
      getComponent: loadAsync(
        () =>
          import(
            /* webpackChunkName: "request_store" */ './components/store/storeRequested'
          )
      )
    })}
    {createRoutes(urlRoutes.requestEditStore, {
      getComponent: loadAsync(
        () =>
          import(
            /* webpackChunkName: "request_store" */ './components/store/storeRequested'
          )
      )
    })}
    {createRoutes(urlRoutes.teamStore, {
      getComponent: loadAsync(
        () =>
          import(
            /* webpackChunkName: "team_store" */ './components/team/landing'
          )
      )
    })}
    {createRoutes(urlRoutes.teamStoreAdd, {
      getComponent: loadAsync(
        () =>
          import(
            /* webpackChunkName: "team_store_add" */ './components/team/add'
          )
      )
    })}
    {createRoutes(urlRoutes.fanthread, {
      getComponent: loadAsync(() => import('./components/fanthread/landing'))
    })}
    {createRoutes(urlRoutes.track, {
      getComponent: loadAsync(
        () =>
          import(
            /* webpackChunkName: "account_track" */ './components/account/track'
          )
      )
    })}
    {createRoutes(urlRoutes.order, {
      getComponent: loadAsync(
        () =>
          import(
            /* webpackChunkName: "account_order" */ './components/account/order'
          )
      )
    })}
    {createRoutes(urlRoutes.customerService, {
      getComponent: loadAsync(
        () =>
          import(
            /* webpackChunkName: "customer_service_page" */ './components/contentful/customerServicePage'
          )
      )
    })}
    {createRoutes(urlRoutes.promoPage, {
      getComponent: loadAsync(
        () =>
          import(
            /* webpackChunkName: "promo_page" */ './components/contentful/promoPage'
          )
      )
    })}
    {createRoutes(urlRoutes.landingPage, {
      getComponent: loadAsync(
        () =>
          import(
            /* webpackChunkName: "landing_page" */ './components/contentful/landingPage'
          )
      )
    })}
    <Route path="/account/**" onEnter={reloadPage} />
    {createRoutes(urlRoutes.designAdmin, {
      getComponent: loadAsync(
        () =>
          import(
            /* webpackChunkName: "admin_design_upload" */ './components/admin/design/custom'
          )
      )
    })}
    {createRoutes(urlRoutes.designQueue, {
      getComponent: loadAsync(
        () =>
          import(
            /* webpackChunkName: "admin_design_queue" */ './components/admin/design/queue'
          )
      )
    })}
    {createRoutes(urlRoutes.stripeCheckout, {
      getComponent: loadAsync(
        () =>
          import(
            /* webpackChunkName: "stripe_checkout_form" */ './components/checkout/stripe_checkout_form'
          )
      )
    })}
    {createRoutes(urlRoutes.offline, {
      getComponent: loadAsync(
        () =>
          import(/* webpackChunkName: "offline" */ './components/error/offline')
      )
    })}
    {createRoutes(urlRoutes.fiveXx, {
      getComponent: loadAsync(
        () =>
          import(/* webpackChunkName: "five_x_x" */ './components/error/5xx')
      )
    })}
    {createRoutes(urlRoutes.fourOhFour, {
      getComponent: loadAsync(
        () =>
          import(
            /* webpackChunkName: "four_oh_four" */ './components/error/404'
          )
      )
    })}
  </Route>
)

Routes.propTypes = {
  dispatch: PropTypes.func.isRequired,
  getState: PropTypes.func.isRequired
}

export default Routes
