const doUntil = async (fns, test) => {
  let preempted = false
  const results = []

  while (!preempted && fns.length) {
    const result = await fns.shift()()
    preempted = test(result)
    !preempted && results.push(result)
  }

  return { results, preempted }
}

export const initialize = async (components, args, context) => {
  let called = false
  const replace = (...args) => {
    called = true
    context.replace(...args)
  }

  const statusCode = (...args) => {
    called = true
    context.statusCode(...args)
  }

  return doUntil(
    components
      .filter((c) => c && c.initialize)
      .map(
        (c) => () =>
          c.initialize({
            ...args,
            replace,
            statusCode,
            userAgent: args.userAgent || context.userAgent
          })
      ),
    () => called
  )
}
