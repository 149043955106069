import urlParse from 'url-parse'

export const propsShallowDiff = (props, nextProps) =>
  Object.keys(nextProps).filter((key) => nextProps[key] !== props[key])

export const canonicalRedirect = (canonical, current, replace) => {
  // Decoding will turn `%20` in to ` ` but not a `+`, so we need to handle that
  // explicitly.
  if (
    decodeURI(canonical).replace(/\+/g, ' ') !==
    decodeURI(current).replace(/\+/g, ' ')
  ) {
    replace(canonical, 301)
    return true
  }

  return false
}

export const qs = (params) =>
  Object.entries(params)
    .map((pair) => pair.map(encodeURIComponent).join('='))
    .join('&')

export const abbreviate = (str = '', limit) =>
  str
    .split(/\s+/g)
    .map((word) => word[0])
    .join('')
    .slice(0, limit)
    .toUpperCase()

export const capitalCamelCase = (str, delim = '') =>
  str
    .split(/\s+/g)
    .map((word) => {
      if (word) {
        return `${word[0].toUpperCase()}${word.slice(1).toLowerCase()}`
      }
    })
    .join(delim)

export const changeHost = (uri, host) => {
  if (!host) {
    return uri
  }

  const { pathname, query, hash } = urlParse(uri)

  return `${host}${pathname}${query}${hash ? `#${hash}` : ''}`
}

export const idleify = (fn, timeout = 3000) => {
  if (!process.browser) {
    return fn
  }

  // Detect test environment and initialize immediately. Puppeteer attempts to
  // interact with the page right away so we need to have initialized and able
  // to handle user interaction.
  if (isTestEnv() || !window.requestIdleCallback) {
    return fn
  }

  return (...args) => requestIdleCallback(() => fn(...args), { timeout })
}

export const isTestEnv = () =>
  process.browser && window.location.hostname === 'local.ps.test'

export const defaultServerUrl = () => {
  return process.browser
    ? location.origin
    : process.env.SERVER_HOST.split(',')[0]
}

export const getServerUrl = (req) => {
  const proto = req.headers['x-forwarded-proto'] || 'http'
  const serverName = req.headers['x-server-name']
  return serverName ? `${proto}://${serverName}` : defaultServerUrl()
}

export const isTrue = (value) => {
  if (typeof value === 'boolean') {
    return value
  } else if (typeof value === 'number') {
    return value === 1
  } else if (typeof value === 'string') {
    value = value.toLowerCase()
    return value === 'true' || value === '1'
  }
  return false
}

export const checkIsProdWithFake = (source) =>
  checkIsProdWithoutFake(source) ||
  source?.env?.FAKE_ENV === 'prod' ||
  source?.env?.FAKE_ENV === 'production' ||
  source?.FAKE_ENV === 'prod' ||
  source?.FAKE_ENV === 'production'

export const checkIsProdWithoutFake = (source) =>
  checkAllEnv(source, 'prod') || checkAllEnv(source, 'production')

const checkAllEnv = (source, value) =>
  [
    source,
    source?.ENV,
    source?.NODE_ENV,
    source?.environment,
    source?.env,
    source?.env?.ENV,
    source?.env?.NODE_ENV,
    source?.env?.environment
  ].includes(value)

export const checkIsLocalUrl = (source) =>
  /local/i.test(source) ||
  /local/i.test(source?.SERVER_URLS) ||
  /local/i.test(source?.env?.SERVER_URLS)

export const checkIsStagingUrl = (source) =>
  /staging/i.test(source) ||
  /staging/i.test(source?.SERVER_URLS) ||
  /staging/i.test(source?.env?.SERVER_URLS)

export const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms))
