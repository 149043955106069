import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  // TODO(wes): Update this method. See:
  // https://reactjs.org/docs/react-component.html#componentdidcatch
  componentDidCatch(error) {
    this.setState({ error })
    try {
      console.error(error)
    } catch (err) {
      // What can we do, you know?
    }
  }

  UNSAFE_componentWillReceiveProps() {
    this.setState({ error: null })
  }

  render() {
    const { children, ...rest } = this.props
    const { error } = this.state

    if (error) {
      return (
        <Container {...rest}>
          <Row>
            <Col xs={12}>
              <h1>OOPS</h1>
              <p>Something just broke on our end. Sorry about that!</p>
            </Col>
          </Row>
        </Container>
      )
    }

    return (
      <div id="body-content" {...rest}>
        {children}
      </div>
    )
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.element
}

export default ErrorBoundary
