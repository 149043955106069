import { createActions } from './api'

const actions = createActions(
  'contentful',
  ['contentful_entries'],
  'contentful_entries'
)

const getContentTypeByUrl = (type, url) =>
  actions.get({
    query: {
      content_type: type,
      'fields.metaData.sys.contentType.sys.id': 'metaData',
      'fields.metaData.fields.canonicalUrl[match]': url,
      include: 3
    }
  })

export const getCustomServicePageByUrl = (url) =>
  getContentTypeByUrl('customerServicePage', url)

export const getLandingPageByUrl = (url) =>
  getContentTypeByUrl('landingPage', url)

export const getPromoPageByUrl = (url) =>
  actions.get({
    query: {
      content_type: 'promotion',
      'fields.link[match]': url,
      include: 3
    }
  })

export const getBanner = () =>
  actions.get({ query: { content_type: 'banner' } })

export const getPromotion = () =>
  actions.get({ query: { content_type: 'promotion' } })

export const getEntryById = (id, include = 3) =>
  actions.get({
    query: {
      'sys.id': id,
      include
    }
  })

export const getSeoPageByUrl = (route) =>
  actions.get({
    query: {
      content_type: 'sitePage',
      'fields.url[in]': route,
      include: 3
    }
  })
