export const defaults = {
  COUNTRY: 'US'
}

export const storeTypes = {
  ADVENTURE: 'adventure',
  COLLEGE: 'college',
  DESTINATION: 'destination',
  GOLF: 'golf',
  GOVERNMENT: 'government',
  GREEK: 'greek',
  LITTLE_LEAGUE: 'littleleague',
  MILITARY: 'military',
  PROSPORTS: 'prosports',
  RACES: 'races',
  SCHOOL: 'school',
  TEAM: 'team',
  VINTAGE_TEAMS: 'vintageteams'
}

export const storeCodes = {
  [storeTypes.ADVENTURE]: 'a',
  [storeTypes.COLLEGE]: 'c',
  [storeTypes.DESTINATION]: 'd',
  [storeTypes.GOLF]: 'l',
  [storeTypes.GOVERNMENT]: 'f',
  [storeTypes.GREEK]: 'g',
  [storeTypes.LITTLE_LEAGUE]: 'k',
  [storeTypes.MILITARY]: 'm',
  [storeTypes.PROSPORTS]: 'w',
  [storeTypes.RACES]: 'e',
  [storeTypes.SCHOOL]: 's',
  [storeTypes.TEAM]: 't',
  [storeTypes.VINTAGE_TEAMS]: 'v'
}

export const storeDisplayNames = {
  [storeTypes.ADVENTURE]: 'Adventures',
  [storeTypes.COLLEGE]: 'College',
  [storeTypes.DESTINATION]: 'Destinations',
  [storeTypes.GOLF]: 'Golf Courses',
  [storeTypes.GOVERNMENT]: 'Government',
  [storeTypes.GREEK]: 'Greek',
  [storeTypes.LITTLE_LEAGUE]: 'Little League',
  [storeTypes.MILITARY]: 'Military',
  [storeTypes.PROSPORTS]: 'Pro Sports',
  [storeTypes.RACES]: 'Races',
  [storeTypes.SCHOOL]: 'Schools',
  [storeTypes.TEAM]: 'Teams',
  [storeTypes.VINTAGE_TEAMS]: 'Vintage Teams'
}

export const navigationNodes = {
  ASSOCIATION_LIST: 'associationlist',
  CHANNEL: 'channel',
  CITY_LIST: 'citylist',
  COLLEGE_LIST: 'colstate',
  CONFERENCE_LIST: 'conferencelist',
  COUNTRY: 'country',
  LEAGUE_LIST: 'leaguelist',
  ORGANIZATION: 'organization',
  STATE_LIST: 'statelist',
  STORE_LIST: 'storelist'
}

export const locationToCodeMapping = {
  '/adventure': 'a',
  '/channel': 's',
  '/college': 'c',
  '/destination': 'd',
  '/golf': 'l',
  '/government': 'f',
  '/greek': 'g',
  '/military': 'm',
  '/prosports': 'w',
  '/races': 'e',
  '/school': 's',
  '/site/(army|air-force|marine|us-navy|national-guard)': 'm',
  '/site/(school|high-school)': 's',
  '/site/college': 'c',
  '/vintageteams': 'v',
  '/(littleleague|little-league)': 'k',
  '/': ['s', 'c']
}
