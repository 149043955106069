import {colors} from './colors'
import {css} from 'glamor'

export const common = {
  colorSwatch: css({
    border: '1px solid black',
    borderRadius: '11px',
    display: 'inline-block',
    height: '22px',
    marginRight: '11px',
    verticalAlign: 'bottom',
    width: '22px'
  }),
  colorSwatchValue: css({
    left: '16px',
    position: 'absolute',
    top: '14px'
  }),
  unstyled: css({
    paddingLeft: 0,
    listStyle: 'none'
  }),
  muted: css({
    color: colors.mutedText
  })
}
