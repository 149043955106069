import { actions } from '@teamrazr/redux'
const { types } = actions

export default function (state = {}, action) {
  switch (action.type) {
    case types.SET_HEADERS:
      return { ...state, ...action.headers }

    default:
      return state
  }
}
