import React from 'react'
import { Helmet } from 'react-helmet'

export const StripeTag = () => {
  // TODO: Remove this when we're done using the legacy checkout!
  return (
    <Helmet>
      <script src="https://js.stripe.com/v3/" async />
    </Helmet>
  )
}
