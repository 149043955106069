import without from 'lodash/without'
import { helpers } from '@teamrazr/redux'

const defaultCartReducer = helpers.createApiReducer('cart')

export default function cartReducer(state = { items: [], count: 0 }, action) {
  switch (action.type) {
    case 'DELETE_CART_SUCCESS': {
      const [cart] = state.items
      const CartLineItems = without(cart.CartLineItems, [
        'CartLineItemID',
        action.query.id
      ])

      return { loading: false, items: [{ ...cart, CartLineItems }] }
    }
    default:
      return defaultCartReducer(state, action)
  }
}
