import { combineReducers } from 'redux'
import { helpers } from '@teamrazr/redux'
import reducers from '@/redux/reducers'

import productDesigns from './productDesigns'
import { billingStateList, shippingAddress, stateList } from './shippingBilling'
import productReviewsReducer from '@/redux/reducers/product_reviews'

const rootReducer = combineReducers({
  ...reducers,
  activities: helpers.createApiReducer('activities'),
  add_to_cart: helpers.createApiReducer('add_to_cart'),
  billing_state_list: billingStateList,
  category: helpers.createApiReducer('category'),
  category_designs: helpers.createApiReducer('category_designs'),
  category_products: helpers.createApiReducer('category_products'),
  channel_list_details: helpers.createApiReducer('channel_list_details'),
  channel_main_details: helpers.createApiReducer('channel_main_details'),
  confirm_details: helpers.createApiReducer('confirm_details'),
  customDesigns: helpers.createApiReducer('customDesigns'),
  delete_cart_item: helpers.createApiReducer('delete_cart_item'),
  design_enums: helpers.createApiReducer('design_enums'),
  design_queue: helpers.createApiReducer('design_queue'),
  designs: helpers.createApiReducer('designs'),
  newsletter_signup: helpers.createApiReducer('newsletter_signup'),
  orders: helpers.createApiReducer('orders'),
  product_designs: productDesigns,
  product_recommendations: helpers.createApiReducer('product_recommendations'),
  shipping_address_details: shippingAddress,
  shipping_billing_details: helpers.createApiReducer(
    'shipping_billing_details'
  ),
  signrequest: helpers.createApiReducer('signrequest'),
  state_list: stateList,
  store: helpers.createApiReducer('store'),
  store_home_products: helpers.createApiReducer('store_home_products'),
  store_products: helpers.createApiReducer('store_products'),
  store_types: helpers.createApiReducer('store_types'),
  storeAdmin: helpers.createApiReducer('storeAdmin'),
  storeClaim: helpers.createApiReducer('storeClaim'),
  storeDesigns: helpers.createApiReducer('storeDesigns'),
  storeMain: helpers.createApiReducer('storeMain'),
  stores: helpers.createApiReducer('stores'),
  submit_team_shop: helpers.createApiReducer('submit_team_shop'),
  products: helpers.createApiReducer('products'),
  product_reviews: productReviewsReducer,
  cached_design_personalizations: helpers.createApiReducer(
    'cached_design_personalizations'
  )
})
export default rootReducer
