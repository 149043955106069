import PropTypes from 'prop-types'
import React from 'react'
import BootstrapButton from 'react-bootstrap/Button'
import Link from 'react-router/lib/Link'
import Icon, { icons } from './icon'

import { css } from 'glamor'
import { colors, palettes, text } from '@/styles'

const style = {
  base: css(text.button, {
    border: 'none',
    borderRadius: '3px',
    boxShadow: 'none',
    cursor: 'pointer',
    display: 'inline-block',
    margin: 0,
    padding: 0,
    position: 'relative',
    textAlign: 'center',
    textDecoration: 'none',
    transition: '0.2s box-shadow',
    '&:focus': {
      textDecoration: 'none'
    },
    '&:hover': {
      textDecoration: 'none'
    },
    '&:active': {
      textDecoration: 'none'
    },
    '&:disabled, &.disabled': {
      cursor: 'default',
      opacity: '100',
      pointerEvents: 'none'
    }
  }),
  flags: {
    block: css({
      width: '100%'
    }),
    icon: css({
      position: 'absolute',
      top: '0',
      right: '20px'
    }),
    pill: css({
      borderRadius: '25px'
    })
  },
  kind: {
    cta: css({
      background: palettes.success.base,
      color: palettes.success.text,
      textShadow: palettes.success.shadow,
      '&:focus': {
        background: palettes.success.base,
        boxShadow: palettes.success.state.focus,
        color: palettes.success.text,
        outline: 'none'
      },
      '&:hover': {
        background: palettes.success.state.hover,
        color: palettes.success.text
      },
      '&:active, &:active:focus, &:active:hover, &.active, &.active:hover': {
        background: palettes.success.state.active,
        color: palettes.success.text,
        outline: 'none'
      },
      '&:disabled, &.disabled': {
        background: palettes.disabled.base,
        color: palettes.disabled.text,
        textShadow: palettes.disabled.shadow
      }
    }),
    link: css({
      display: 'inline',
      fontSize: 'inherit',
      fontWeight: 'inherit',
      height: 'auto',
      lineHeight: 'inherit',
      textTransform: 'inherit',
      verticalAlign: 'top',
      width: 'auto',
      '&:hover': {
        textDecoration: 'underline'
      },
      '> .fa': {
        position: 'relative',
        top: '-0.1em'
      }
    }),
    outline: css({
      background: palettes.outline.base,
      border: `1px solid ${palettes.outline.border}`,
      boxShadow: 'none',
      color: palettes.outline.text,
      fontWeight: 700,
      textShadow: 'none',
      '&:focus': {
        background: palettes.outline.state.focus,
        color: palettes.outline.text
      },
      '&:hover': {
        background: palettes.outline.state.hover,
        color: palettes.outline.text
      },
      '&:active, &:active:focus, &:active:hover, &.active, &.active:hover': {
        background: palettes.outline.state.active,
        color: palettes.outline.text
      }
    }),
    primary: css({
      background: palettes.primary.base,
      color: palettes.primary.text,
      textShadow: palettes.primary.shadow,
      '&:focus': {
        background: palettes.primary.base,
        boxShadow: palettes.primary.state.focus,
        color: palettes.primary.text,
        outline: 'none'
      },
      '&:hover': {
        background: palettes.primary.state.hover,
        color: palettes.primary.text
      },
      '&:active, &:active:focus, &:active:hover, &.active, &.active:hover': {
        background: palettes.primary.state.active,
        color: palettes.primary.text,
        outline: 'none'
      },
      '&:disabled, &.disabled': {
        background: palettes.disabled.base,
        color: palettes.disabled.text,
        textShadow: palettes.disabled.shadow
      }
    }),
    secondary: css({
      background: palettes.secondary.base,
      color: palettes.secondary.text,
      textShadow: palettes.secondary.shadow,
      '&:focus': {
        background: palettes.secondary.base,
        boxShadow: palettes.secondary.state.focus,
        color: palettes.secondary.text,
        outline: 'none'
      },
      '&:hover': {
        background: palettes.secondary.state.hover,
        color: palettes.secondary.text
      },
      '&:active, &:active:focus, &:active:hover, &.active, &.active:hover': {
        background: palettes.secondary.state.active,
        color: palettes.secondary.text,
        outline: 'none'
      },
      '&:disabled, &.disabled': {
        background: palettes.disabled.base,
        color: palettes.disabled.text,
        textShadow: palettes.disabled.shadow
      }
    }),
    stroke: css({
      background: 'transparent',
      border: `1px solid ${colors.defaultBorder}`,
      color: colors.strokeText,
      fontWeight: 'inherit',
      padding: '0 20px',
      textAlign: 'left',
      textShadow: 'none',
      textTransform: 'inherit',
      '&:focus': {
        background: colors.strokeHover,
        color: colors.strokeText
      },
      '&:hover': {
        background: colors.strokeHover,
        color: colors.strokeText
      },
      '&:active, &:active:focus, &:active:hover, &.active, &.active:hover': {
        background: colors.strokeActive,
        boxShadow: 'none',
        color: colors.strokeText
      },
      '&:disabled, &.disabled': {
        background: palettes.disabled.base,
        color: palettes.disabled.text,
        textShadow: palettes.disabled.shadow
      },
      '@media (max-width: 991px)': {
        borderColor: colors.lightBorder,
        borderLeft: 'none',
        borderRadius: 0,
        borderRight: 'none',
        boxShadow: 'none',
        margin: '0 -15px',
        width: 'calc(100% + 30px)'
      },
      '& .fa': {
        color: colors.inputIcon
      }
    })
  },
  sizes: {
    large: css({
      height: '50px',
      lineHeight: '50px',
      width: '300px'
    }),
    small: css({
      height: '36px',
      lineHeight: '36px',
      width: '150px'
    })
  }
}

const HrefLink = ({ anchor, href, children, ...props }) =>
  anchor ? (
    <a href={href} rel="noopener noreferer" {...props}>
      {children}
    </a>
  ) : (
    <Link to={href} {...props}>
      {children}
    </Link>
  )

HrefLink.propTypes = {
  anchor: PropTypes.bool,
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired
}

const Button = ({
  block = false,
  disabled = false,
  kind = 'primary',
  pill = false,
  size = 'large',
  children,
  className,
  href,
  icon,
  onClick,
  style: customStyle,
  type,
  ...rest
}) => {
  let elementStyle = style.base

  switch (size) {
    case 'small':
      elementStyle = css(elementStyle, style.sizes[size])
      break

    case 'large':
    default:
      elementStyle = css(elementStyle, style.sizes.large)
      break
  }

  switch (kind) {
    case 'cta':
    case 'link':
    case 'outline':
    case 'secondary':
    case 'stroke':
      elementStyle = css(elementStyle, style.kind[kind])
      break

    case 'primary':
    default:
      elementStyle = css(elementStyle, style.kind.primary)
      break
  }

  if (pill) {
    elementStyle = css(elementStyle, style.flags.pill)
  }

  if (block) {
    elementStyle = css(elementStyle, style.flags.block)
  }

  elementStyle = css(elementStyle, customStyle)

  return (
    <BootstrapButton
      className={className}
      as={href ? HrefLink : undefined}
      variant={kind === 'link' ? 'link' : 'default'}
      disabled={disabled}
      href={href}
      onClick={onClick}
      type={type}
      {...rest}
      {...elementStyle}
    >
      {children}
      {icon && (
        <div {...style.flags.icon}>
          <Icon icon={icon} />
        </div>
      )}
      {kind === 'stroke' && (
        <div {...style.flags.icon}>
          <Icon icon={icons.chevronRight} />
        </div>
      )}
    </BootstrapButton>
  )
}

Button.propTypes = {
  block: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  href: PropTypes.string,
  kind: PropTypes.oneOf([
    'cta',
    'link',
    'outline',
    'primary',
    'secondary',
    'stroke'
  ]),
  icon: PropTypes.string,
  onClick: PropTypes.func,
  pill: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'large', 'block']),
  style: PropTypes.object,
  type: PropTypes.string
}

export default Button
