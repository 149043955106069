import FlushingQueue from '../shared/flushingQueue'
import PropTypes from 'prop-types'
import React, { Component, createContext } from 'react'
import fetch from 'isomorphic-fetch'
import isNil from 'lodash/isNil'
import omitBy from 'lodash/omitBy'

export const ViewContext = createContext(undefined)
export const IndexContext = createContext(undefined)

export const EffectEvent = (props) => (
  <ViewContext.Consumer>
    {(view) => (
      <IndexContext.Consumer>
        {(index) => <EffectEventHelper view={view} index={index} {...props} />}
      </IndexContext.Consumer>
    )}
  </ViewContext.Consumer>
)

export const PushContext = createContext(() => null)

class EffectEventHelper extends Component {
  componentDidMount() {
    this.context(this.props)
  }

  render() {
    return null
  }
}

EffectEventHelper.contextType = PushContext

export class TrackingProvider extends Component {
  constructor(props) {
    super(props)

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { children, ...options } = props

    const queue = new FlushingQueue({
      ...options,
      onFlush: (events) =>
        fetch('/api/bq', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ events })
        })
    })

    this.push = (data) =>
      queue.push(omitBy({ timestamp: new Date(), ...data }, isNil))
  }

  render() {
    return (
      <PushContext.Provider value={this.push}>
        {this.props.children}
      </PushContext.Provider>
    )
  }
}

TrackingProvider.propTypes = {
  children: PropTypes.any
}

export class TrackingConsumer extends Component {
  render() {
    const { children } = this.props

    return (
      <ViewContext.Consumer>
        {(view) => (
          <IndexContext.Consumer>
            {(index) =>
              children((data) => this.context({ view, index, ...data }))
            }
          </IndexContext.Consumer>
        )}
      </ViewContext.Consumer>
    )
  }
}

TrackingConsumer.contextType = PushContext
TrackingConsumer.propTypes = { children: PropTypes.any }
