// This import must be first to allow Glamor CSS overrides
import '../styles/theme/main.scss'

import { helpers } from '@teamrazr/redux'
import { rehydrate } from 'glamor'
import React from 'react'
import { hydrateRoot } from 'react-dom/client'
import match from 'react-router/es/match'
import browserHistory from 'react-router/lib/browserHistory'
import { applyMiddleware, compose, createStore } from 'redux'
import thunkMiddleware from 'redux-thunk'
import Root from '../components/root'
import { idleify } from '../shared/utils'
import reducers from '@/bundle/client/reducers'

// Rehydrate must be called before any components that use glamor are loaded.
// Since es6 does not guarantee load order for `import` syntax we must use
// a separate function and a require call (wherever init.js is used). See:
// https://github.com/threepointone/glamor/issues/37#issuecomment-257831193
export const rehydrateCss = () => {
  rehydrate(window.__INIT_CSS__)
}

const hydrateAndTeardown = (domNode, reactNode) => {
  hydrateRoot(domNode, reactNode)
  window.__CLIENT_TEARDOWN__.map((f) => f())
}
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export const store = createStore(
  reducers,
  window.__INIT_STATE__,
  composeEnhancers(applyMiddleware(thunkMiddleware, helpers.fetchMiddleware))
)

export const renderApp = (routes) => {
  const createdRoutes = routes(store)

  // Calling match makes sure any async routes are loaded before we hydrate
  // Note: We make sure to use the same pathname the server used to render this
  // page.
  //
  // Note: Both `match` and `hydrate` are expensive calls and use
  // requestIdleCallback if available.
  idleify(match)(
    {
      location: { ...location, pathname: store.getState().pathname },
      history: browserHistory,
      routes: createdRoutes
    },
    (err, redirect, props) => {
      idleify(hydrateAndTeardown)(
        document.getElementById('app'),
        <Root {...props} store={store} />
      )
    }
  )
}
