import find from 'lodash/find'

const productDesignsType = 'PRODUCT_DESIGNS'
const previewProductType = 'PREVIEW_PRODUCT'

const initialState = {
  items: {
    productMain: {
      ProductPA: []
    }
  }
}

export default function productDesigns(state = initialState, action) {
  const modified = Date.now()
  const loading = false
  const { body } = action

  switch (action.type) {
    case `GET_${productDesignsType}`:
    case `CREATE_${previewProductType}`:
      return { ...state, loading: true }

    case `GET_${productDesignsType}_ERROR`:
    case `CREATE_${previewProductType}_ERROR`:
      return { ...state, loading }

    case `GET_${productDesignsType}_SUCCESS`:
      return {
        items: body,
        loading,
        modified
      }

    case `CREATE_${previewProductType}_SUCCESS`:
      return {
        ...state,
        items: {
          ...state.items,
          productMain: {
            ...state.items.productMain,
            ProductPA: state.items.productMain.ProductPA.map((productPA) => {
              if (productPA.Name !== action.query.productPAName) {
                return productPA
              }

              const paDesigns =
                body.PADesigns[`${productPA.Name} designs`] ||
                body.PADesigns[`${productPA.VName} designs`]

              const selectedDesign =
                find(paDesigns, {
                  id: action.query.selectedDesignId
                }) || paDesigns[0]

              return {
                ...productPA,
                BlankDesignId: body.BlankDesignId,
                DesignBulkCapable: body.DesignBulkCapable,
                SelectedDesignId: selectedDesign.id,
                Designs: body.PADesigns
              }
            }),
            SizeQty: body.SizeQty
          }
        },
        loading,
        modified
      }

    default:
      return state
  }
}
