const defaultOptions = {
  batchSize: 15,
  flushTimeout: 1000,
  onFlush: (data) => console.log('onFlush', data)
}

export default class FlushingQueue {
  constructor(options = {}) {
    this.options = { ...defaultOptions, ...options }
    this.data = []
  }

  push(item) {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }

    this.data.push(item)

    if (this.data.length >= this.options.batchSize) {
      this.flush()
    } else if (this.options.flushTimeout) {
      this.timeout = setTimeout(this.flush, this.options.flushTimeout)
    }
  }

  flush = () => {
    if (this.data.length) {
      const data = [...this.data]
      this.data = []
      this.options.onFlush(data)
    }
  }
}
