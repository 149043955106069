export const breakpoints = {
  xs: '(max-width: 575px)',
  sm: '(max-width: 767px) and (min-width: 576px)',
  md: '(max-width: 991px) and (min-width: 768px)',
  lg: '(max-width: 1199px) and (min-width: 992px)',
  xl: '(min-width: 1200px)'
}

export const media = {
  xs: (rules) => ({
    [`@media ${breakpoints.xs}`]: rules
  }),
  sm: (rules) => ({
    [`@media ${breakpoints.sm}`]: rules
  }),
  md: (rules) => ({
    [`@media ${breakpoints.md}`]: rules
  }),
  lg: (rules) => ({
    [`@media ${breakpoints.lg}`]: rules
  }),
  xl: (rules) => ({
    [`@media ${breakpoints.xl}`]: rules
  }),
  xsAndSm: (rules) => ({
    [`@media ${breakpoints.xs}`]: rules,
    [`@media ${breakpoints.sm}`]: rules
  }),
  mobile: (rules) => ({
    [`@media ${breakpoints.xs}`]: rules,
    [`@media ${breakpoints.sm}`]: rules,
    [`@media ${breakpoints.md}`]: rules
  }),
  desktop: (rules) => ({
    [`@media ${breakpoints.lg}`]: rules,
    [`@media ${breakpoints.xl}`]: rules
  })
}
