import { actions } from '@teamrazr/redux'
const { types } = actions

export default (state = null, action) => {
  switch (action.type) {
    case types.RESET_ERROR:
      return null

    case types.CREATE_EMAIL_SIGNUP_ERROR:
      return { ...action, emailSignup: true, message: action.err.message }

    default:
      return state
  }
}
