import PropTypes from 'prop-types'
import React from 'react'
import Router from 'react-router/lib/Router'
import { Provider } from 'react-redux'

const Root = ({ store, ...props }) => (
  <Provider store={store}>
    <Router {...props} />
  </Provider>
)

Root.propTypes = {
  store: PropTypes.object.isRequired
}

export default Root
