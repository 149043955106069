import head from 'lodash/head'
import { helpers } from '@teamrazr/redux'

const searchAction = helpers.createAction('SEARCH', 'query', 'source')
const searchErrorAction = helpers.createAction('SEARCH_ERROR', 'err')
const searchSuccessAction = helpers.createAction(
  'SEARCH_SUCCESS',
  'items',
  'query'
)

export const reset = helpers.createAction('SEARCH_RESET')

export const search =
  (query, type, source, page = 0) =>
  async (dispatch, getState) => {
    if (!(type instanceof Array)) {
      type = [type]
    }
    dispatch(searchAction(query, source))

    const {
      algolia: { api_key, app_id, index }
    } = getState()
    const { default: AlgoliaSearch } = await import(
      /* webpackChunkName: "algoliasearch" */ 'algoliasearch/lite'
    )
    const client = AlgoliaSearch(app_id, api_key)

    try {
      const { results } = await client.search([
        {
          query,
          indexName: index,
          params: {
            hitsPerPage: 5,
            page: page,
            // Note: Multiple filters are OR'd together
            facetFilters: [type.map((t) => `StoreType:${t}`)]
          }
        }
      ])
      dispatch(searchSuccessAction(head(results), query))
    } catch (err) {
      dispatch(searchErrorAction(err))
    }
  }
