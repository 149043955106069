import NProgress from 'nprogress'
import flattenDeep from 'lodash/flattenDeep'
import { initialize } from '@/shared/components'
import { reset as resetSearch } from '../redux/actions/algolia'

const scrollToTop = () => {
  window.scrollTo(0, 0)
  /**
   * Safari doesn't want to scroll to the top on page navigation.
   * I think it has something to do with lazy loading or page load times.
   * It doesn't seem to affect Chrome.
   * This is a quick fix, but if it keeps happening, then I will need to investigate it further.
   */
  setTimeout(() => window.scrollTo(0, 0), 0)
  setTimeout(() => window.scrollTo(0, 0), 100)
}

export const loadAsync =
  (promise, key = 'default') =>
  async () => {
    const { [key]: component } = await promise()
    return component
  }

export const onChange = (dispatch, getState) => {
  NProgress.configure({
    showSpinner: false
  })

  // A promise used below to keep track of component initialization
  let componentsInit

  return async (prevState, nextState, replace, callback) => {
    dispatch(resetSearch())

    // If we're doing a replace, wait for any pending initialization to
    // complete before allowing a route change.
    if (nextState.location.action === 'REPLACE') {
      return componentsInit ? componentsInit.then(callback) : callback()
    }

    const componentsToInitialize = (
      await Promise.all(
        nextState.routes.map((route) =>
          // No need to re-init Main component on every transition
          route.path === '/'
            ? null
            : route.getComponent
              ? route.getComponent()
              : Promise.resolve(route.component)
        )
      )
    ).filter(
      (component) => component && typeof component.initialize === 'function'
    )

    if (!componentsToInitialize.length) {
      scrollToTop()
      return callback()
    }

    NProgress.start()

    componentsInit = initialize(
      componentsToInitialize,
      {
        dispatch,
        getState,
        ...nextState
      },
      {
        replace,
        statusCode: () => (location.href = location.href), // eslint-disable-line no-self-assign
        userAgent: navigator.userAgent
      }
    ).then((results) => {
      componentsInit = null
      NProgress.done()

      const redirect = flattenDeep(results).find(
        (r) => r && r.body && r.body.success === false && r.body.redirectUrl
      )
      if (redirect) {
        const { redirectUrl } = redirect.body
        replace(
          redirectUrl.indexOf('http') === 0
            ? redirectUrl.split(/https?:\/\/.+?\//)[1]
            : redirectUrl
        )
      }

      callback()
      scrollToTop()
    })

    return componentsInit
  }
}

export const reloadPage = (nextState, replace, callback) => {
  const { action, pathname, query } = nextState.location

  if (process.browser && action === 'PUSH') {
    const qs = Object.entries(query)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join('&')

    window.location.href = qs ? `${pathname}?${qs}` : pathname
  } else {
    callback()
  }
}
