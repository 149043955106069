import { actions, helpers } from '@teamrazr/redux'

const { types } = actions

export function shippingAddress(
  state = { items: null, loading: false },
  action
) {
  const loading = false
  const { body } = action

  // PREP 6868 Debugging trying to find where and why ShippingMethodID gets set to 0

  switch (action.type) {
    case types.GET_SHIPPING_BILLING_DETAILS:
    case types.CREATE_SHIPPING_ADDRESS_DETAILS: {
      const updateState = {
        ...state,
        loading: true
      }
      // PREP 6868 Debugging trying to find where and why ShippingMethodID gets set to 0
      if (!updateState?.items?.SelectedShippingMethodID) {
        console.warn(
          'No SelectedShippingMethodID set',
          updateState?.items?.SelectedShippingMethodID
        )
      }
      return updateState
    }
    case types.GET_SHIPPING_BILLING_DETAILS_SUCCESS: {
      const updateState = {
        ...state,
        items: body.model.OrderSummary,
        loading
      }
      // PREP 6868 Debugging trying to find where and why ShippingMethodID gets set to 0
      if (!updateState?.items?.SelectedShippingMethodID) {
        console.warn(
          'No SelectedShippingMethodID set',
          updateState?.items?.SelectedShippingMethodID
        )
      }
      return updateState
    }
    case types.CREATE_SHIPPING_ADDRESS_DETAILS_SUCCESS: {
      const updateState = {
        ...state,
        items: {
          ...body.OrderSummary,
          SelectedShippingMethodID: body.SelectedShippingMethodID
        },
        loading
      }
      // PREP 6868 Debugging trying to find where and why ShippingMethodID gets set to 0
      if (!updateState?.items?.SelectedShippingMethodID) {
        console.warn(
          'No SelectedShippingMethodID set',
          updateState?.items?.SelectedShippingMethodID
        )
      }
      return updateState
    }
    case types.GET_SHIPPING_BILLING_DETAILS_ERROR:
    case types.CREATE_SHIPPING_ADDRESS_DETAILS_ERROR: {
      const updateState = { ...state, loading }
      // PREP 6868 Debugging trying to find where and why ShippingMethodID gets set to 0
      if (!updateState?.items?.SelectedShippingMethodID) {
        console.warn(
          'No SelectedShippingMethodID set',
          updateState?.items?.SelectedShippingMethodID
        )
      }
      return updateState
    }
    default:
      return state
  }
}

const stateListReducer = helpers.createApiReducer('state_list')

export function stateList(state, action) {
  switch (action.type) {
    // The API returns an empty response which is invalid JSON and throws an
    // error, so we handle it appropriately here.
    case types.GET_STATE_LIST_ERROR:
      return { items: [], loading: false }

    default:
      return stateListReducer(state, action)
  }
}

const billingStateListReducer = helpers.createApiReducer('billing_state_list')

export function billingStateList(state, action) {
  switch (action.type) {
    // The API returns an empty response which is invalid JSON and throws an
    // error, so we handle it appropriately here.
    case types.GET_BILLING_STATE_LIST_ERROR:
      return { items: [], loading: false }

    default:
      return billingStateListReducer(state, action)
  }
}
