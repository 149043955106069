import isNil from 'lodash/isNil'
import omitBy from 'lodash/omitBy'
import { helpers } from '@teamrazr/redux'

export const createApi = (url) =>
  helpers.createApi(url, {
    mapStateToOptions: ({ cookie, userAgent }) => {
      if (process.browser) {
        return {}
      }

      return {
        headers: { ...omitBy({ cookie, 'user-agent': userAgent }, isNil) }
      }
    }
  })
