import { createActions } from './api'
import { queryTypes } from '@/constants/cart'

/** @type {App.Actions.CreateActions<App.Prep.Models.Cart, 'cart'>} */
const cart = createActions('cart')

/**
 *
 * @param {boolean} [cached=false]
 * @param {string} [type='full']
 */
export const fetchCart = (cached = false, type = queryTypes.FULL) =>
  cart.get({ cached, query: { type } })

export const createCartLineItem = (body) =>
  cart.create({ path: '/items', options: { body } })

export const updateCartLineItem = (id, body) =>
  cart.update({
    path: `/items/${id}`,
    options: { body },
    merge: (c) => c.CartLineItems.some((cli) => cli.CartLineItemID === id)
  })

export const deleteCartLineItem = (id) =>
  cart.delete({
    path: '/items',
    query: { id },
    merge: (c) => c.CartLineItems.some((cli) => cli.CartLineItemID === id)
  })
