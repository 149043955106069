import { actions } from '@teamrazr/redux'
const { types } = actions

export default function (state = { items: [], loading: false }, action) {
  const loading = false

  switch (action.type) {
    case types.SEARCH:
      return {
        ...state,
        loading: true,
        query: action.query,
        source: action.source
      }

    case types.SEARCH_SUCCESS:
      return {
        ...state,
        items: action.items,
        loading,
        query: action.query
      }

    case types.SEARCH_ERROR:
      return { ...state, loading }

    case types.SEARCH_RESET:
      return { items: [], loading: false }

    default:
      return state
  }
}
