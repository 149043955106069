import PropTypes from 'prop-types'
import React from 'react'
import { palettes, media, text } from '../styles'
import { css } from 'glamor'

const style = {
  banner: css(text.bodySmallBold, {
    padding: '0.8em',
    background: palettes.default.text,
    color: palettes.primary.text,
    textAlign: 'center',
    '& > a': {
      color: 'inherit'
    },
    '& span': {
      display: 'inline-block'
    },
    '& .discount-text': {
      fontWeight: '400',
      '> .discount-code': {
        color: palettes.primary.base,
        fontWeight: '700'
      },
      ...media.desktop({
        border: '2px solid #fff',
        padding: '10px',
        margin: '0 10px'
      })
    },
    '& .title': {
      margin: '0 5px'
    },
    ...media.xs(text.h5)
  })
}

const Banner = ({ children, style: customStyle }) => (
  <section {...css(style.banner, customStyle)}>{children}</section>
)

Banner.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.object
}

export default Banner
